import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ENDPOINTS } from 'src/api';
import stbServer from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: null,
  contacts: {
    byId: {
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
        name: 'Jayvion Simon',
        username: 'jayvion.simon',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg',
        address: '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
        phone: '365-374-4961',
        email: 'nannie_abernathy70@yahoo.com',
        lastActivity: '2023-03-29T09:55:14.008Z',
        status: 'away',
        position: 'UX Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        name: 'Lucian Obrien',
        username: 'lucian.obrien',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg',
        address: '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
        phone: '904-966-2836',
        email: 'ashlynn_ohara62@gmail.com',
        lastActivity: '2023-03-28T08:55:14.008Z',
        status: 'busy',
        position: 'Full Stack Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
        name: 'Deja Brady',
        username: 'deja.brady',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg',
        address: '18605 Thompson Circle Apt. 086 - Idaho Falls, WV / 50337',
        phone: '399-757-9909',
        email: 'milo.farrell@hotmail.com',
        lastActivity: '2023-03-27T07:55:14.009Z',
        status: 'offline',
        position: 'Backend Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
        name: 'Harrison Stein',
        username: 'harrison.stein',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg',
        address: '110 Lamar Station Apt. 730 - Hagerstown, OK / 49808',
        phone: '692-767-2903',
        email: 'violet.ratke86@yahoo.com',
        lastActivity: '2023-03-26T06:55:14.010Z',
        status: 'online',
        position: 'UX Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
        name: 'Reece Chung',
        username: 'reece.chung',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg',
        address: '36901 Elmer Spurs Apt. 762 - Miramar, DE / 92836',
        phone: '990-588-5716',
        email: 'letha_lubowitz24@yahoo.com',
        lastActivity: '2023-03-25T05:55:14.010Z',
        status: 'away',
        position: 'UX Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
        name: 'Lainey Davidson',
        username: 'lainey.davidson',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg',
        address: '2089 Runolfsson Harbors Suite 886 - Chapel Hill, TX / 32827',
        phone: '955-439-2578',
        email: 'aditya_greenfelder31@gmail.com',
        lastActivity: '2023-03-24T04:55:14.010Z',
        status: 'away',
        position: 'Project Manager',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
        name: 'Cristopher Cardenas',
        username: 'cristopher.cardenas',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_7.jpg',
        address: '279 Karolann Ports Apt. 774 - Prescott Valley, WV / 53905',
        phone: '226-924-4058',
        email: 'lenna_bergnaum27@hotmail.com',
        lastActivity: '2023-03-23T03:55:14.010Z',
        status: 'online',
        position: 'Leader',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
        name: 'Melanie Noble',
        username: 'melanie.noble',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg',
        address: '96607 Claire Square Suite 591 - St. Louis Park, HI / 40802',
        phone: '552-917-1454',
        email: 'luella.ryan33@gmail.com',
        lastActivity: '2023-03-22T02:55:14.010Z',
        status: 'offline',
        position: 'Backend Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
        name: 'Chase Day',
        username: 'chase.day',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_9.jpg',
        address: '9388 Auer Station Suite 573 - Honolulu, AK / 98024',
        phone: '285-840-9338',
        email: 'joana.simonis84@gmail.com',
        lastActivity: '2023-03-21T01:55:14.010Z',
        status: 'offline',
        position: 'Project Manager',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
        name: 'Shawn Manning',
        username: 'shawn.manning',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_10.jpg',
        address: '47665 Adaline Squares Suite 510 - Blacksburg, NE / 53515',
        phone: '306-269-2446',
        email: 'marjolaine_white94@gmail.com',
        lastActivity: '2023-03-20T00:55:14.010Z',
        status: 'busy',
        position: 'UI Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
        name: 'Soren Durham',
        username: 'soren.durham',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_11.jpg',
        address: '989 Vernice Flats Apt. 183 - Billings, NV / 04147',
        phone: '883-373-6253',
        email: 'vergie_block82@hotmail.com',
        lastActivity: '2023-03-18T23:55:14.010Z',
        status: 'away',
        position: 'UI/UX Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12',
        name: 'Cortez Herring',
        username: 'cortez.herring',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_12.jpg',
        address: '91020 Wehner Locks Apt. 673 - Albany, WY / 68763',
        phone: '476-509-8866',
        email: 'vito.hudson@hotmail.com',
        lastActivity: '2023-03-17T22:55:14.010Z',
        status: 'away',
        position: 'UI/UX Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13',
        name: 'Brycen Jimenez',
        username: 'brycen.jimenez',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_13.jpg',
        address: '585 Candelario Pass Suite 090 - Columbus, LA / 25376',
        phone: '201-465-1954',
        email: 'tyrel_greenholt@gmail.com',
        lastActivity: '2023-03-16T21:55:14.010Z',
        status: 'busy',
        position: 'UI Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b14': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b14',
        name: 'Giana Brandt',
        username: 'giana.brandt',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_14.jpg',
        address: '80988 Renner Crest Apt. 000 - Fargo, VA / 24266',
        phone: '538-295-9408',
        email: 'dwight.block85@yahoo.com',
        lastActivity: '2023-03-15T20:55:14.010Z',
        status: 'busy',
        position: 'Backend Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15',
        name: 'Aspen Schmitt',
        username: 'aspen.schmitt',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
        address: '28307 Shayne Pike Suite 523 - North Las Vegas, AZ / 28550',
        phone: '531-492-6028',
        email: 'mireya13@hotmail.com',
        lastActivity: '2023-03-14T19:55:14.010Z',
        status: 'online',
        position: 'Backend Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b16': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b16',
        name: 'Colten Aguilar',
        username: 'colten.aguilar',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_16.jpg',
        address: '205 Farrell Highway Suite 333 - Rock Hill, OK / 63421',
        phone: '981-699-7588',
        email: 'dasia_jenkins@hotmail.com',
        lastActivity: '2023-03-13T18:55:14.010Z',
        status: 'busy',
        position: 'Front End Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
        name: 'Angelique Morse',
        username: 'angelique.morse',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_17.jpg',
        address: '253 Kara Motorway Suite 821 - Manchester, SD / 09331',
        phone: '500-268-4826',
        email: 'benny89@yahoo.com',
        lastActivity: '2023-03-12T17:55:14.010Z',
        status: 'online',
        position: 'Backend Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b18': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b18',
        name: 'Selina Boyer',
        username: 'selina.boyer',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_18.jpg',
        address: '13663 Kiara Oval Suite 606 - Missoula, AR / 44478',
        phone: '205-952-3828',
        email: 'dawn.goyette@gmail.com',
        lastActivity: '2023-03-11T16:55:14.010Z',
        status: 'busy',
        position: 'Full Stack Designer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b19': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b19',
        name: 'Lawson Bass',
        username: 'lawson.bass',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_19.jpg',
        address: '8110 Claire Port Apt. 703 - Anchorage, TN / 01753',
        phone: '222-255-5190',
        email: 'zella_hickle4@yahoo.com',
        lastActivity: '2023-03-10T15:55:14.010Z',
        status: 'online',
        position: 'Full Stack Developer',
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b20': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b20',
        name: 'Ariana Lang',
        username: 'ariana.lang',
        avatar:
          'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_20.jpg',
        address: '4642 Demetris Lane Suite 407 - Edmond, AZ / 60888',
        phone: '408-439-8033',
        email: 'avery43@hotmail.com',
        lastActivity: '2023-03-09T14:55:14.010Z',
        status: 'busy',
        position: 'Backend Developer',
      },
    },
    allIds: [
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b14',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b16',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b18',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b19',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b20',
    ],
  },
  conversations: {
    byId: {
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
            name: 'Lucian Obrien',
            username: 'lucian.obrien',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg',
            address: '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
            phone: '904-966-2836',
            email: 'ashlynn_ohara62@gmail.com',
            lastActivity: '2023-03-28T08:55:12.964Z',
            status: 'offline',
            position: 'Full Stack Designer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: 'f6576f9d-a0cb-4e98-b355-5b0d90e6aa36',
            body: 'Quis veniam aut saepe aliquid nulla.',
            contentType: 'text',
            attachments: [
              'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_2.jpg',
            ],
            createdAt: '2023-03-28T23:55:12.965Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
          },
          {
            id: '607be577-6dc0-4649-bc94-b39fc0b0ed7a',
            body: 'Reprehenderit ut voluptas sapiente ratione nostrum est.',
            contentType: 'text',
            attachments: [
              'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_3.jpg',
            ],
            createdAt: '2023-03-29T07:55:12.965Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '5f86716d-0c88-44a2-bd80-30a3f2eeda9d',
            body: 'Error ut sit vel molestias velit.',
            contentType: 'text',
            attachments: [
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_12.mp4',
            ],
            createdAt: '2023-03-29T09:47:12.965Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
          },
          {
            id: 'c9731c81-dc16-47a7-a492-fc5dc5add79d',
            body: 'Quo quia sit nihil nemo doloremque et.',
            contentType: 'text',
            attachments: [
              'https://mail.google.com/mail/u/file1.docx',
              'https://mail.google.com/mail/u/file2.xlsx',
              'https://mail.google.com/mail/u/file3.pptx',
            ],
            createdAt: '2023-03-29T09:49:12.965Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '1d30e643-96ae-4695-a1c3-01ccb1501f6b',
            body: 'Autem doloribus harum vero laborum.',
            contentType: 'text',
            attachments: [
              'https://mail.google.com/mail/u/file4.pdf',
              'https://mail.google.com/mail/u/file5.psd',
              'https://mail.google.com/mail/u/file6.esp',
              'https://mail.google.com/mail/u/file7.sketch',
            ],
            createdAt: '2023-03-29T09:51:12.965Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
          },
          {
            id: 'a50eaac2-88df-4a77-ace2-e0e7319b0973',
            attachments: [],
            contentType: 'image',
            body: 'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_5.jpg',
            createdAt: '2023-03-29T09:53:12.965Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
          },
          {
            id: '8aa705e2-9843-451f-8e1a-0d2adc488e93',
            contentType: 'text',
            body: 'Tempora officiis consequuntur architecto nostrum autem nam adipisci.',
            attachments: [],
            createdAt: '2023-03-29T09:53:12.965Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '4c91839f-a47f-4ff9-acfd-ce51458ef6dd',
            body: 'Voluptas sunt magni adipisci praesentium saepe.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:53:12.965Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '407a9dc4-d19f-472c-ac43-0b7ab43acbc8',
            body: 'asdd',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:55:27.471Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
            name: 'Deja Brady',
            username: 'deja.brady',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg',
            address: '18605 Thompson Circle Apt. 086 - Idaho Falls, WV / 50337',
            phone: '399-757-9909',
            email: 'milo.farrell@hotmail.com',
            lastActivity: '2023-03-27T07:55:14.009Z',
            status: 'offline',
            position: 'Backend Developer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: '1e26bac3-53af-4d73-8320-b7f3b91366a0',
            body: 'Ea architecto quas voluptates voluptas earum illo est vel rem.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T01:55:14.019Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
          },
          {
            id: 'e3d23e88-1148-4de3-8f19-6b366c15c23c',
            body: 'Ipsum expedita reiciendis ut.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T03:55:14.019Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'b3907be2-f93f-4219-a174-12f288618375',
            body: 'Architecto vel voluptatibus alias a aut non maxime ipsa voluptates.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T05:25:14.019Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
          },
          {
            id: 'ebcd2d25-bddb-4f81-82c4-eb407b2e97cd',
            body: 'Reiciendis enim officiis cupiditate eaque distinctio laudantium modi similique consequatur.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T07:40:14.019Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '852938ab-5c50-46cb-93d5-342338eb412b',
            body: 'Ab autem consequatur itaque mollitia ipsum cupiditate error repudiandae nobis.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T08:40:14.019Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
          },
          {
            id: '1653f1f0-1821-4395-b84c-22dc04f4bfeb',
            body: 'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_8.jpg',
            attachments: [],
            contentType: 'image',
            createdAt: '2023-03-29T08:55:14.019Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
          },
          {
            id: '9f820f2c-2948-4eae-93c2-caf2806d0de0',
            body: 'Distinctio architecto debitis eligendi consequatur unde id modi accusantium possimus.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:10:14.019Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
            name: 'Harrison Stein',
            username: 'harrison.stein',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg',
            address: '110 Lamar Station Apt. 730 - Hagerstown, OK / 49808',
            phone: '692-767-2903',
            email: 'violet.ratke86@yahoo.com',
            lastActivity: '2023-03-26T07:32:42.571Z',
            status: 'offline',
            position: 'UX Designer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: 'eaefc3e5-5b97-409c-9618-4844dbf5e27f',
            body: 'At ut voluptate accusantium.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T02:32:42.576Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          },
          {
            id: '37e6c531-83cd-4b7d-b423-5b4372e440a2',
            body: 'Repudiandae ut qui veritatis sint.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T04:32:42.576Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'd9cae63d-159a-478c-8cb8-0430974ce64a',
            body: 'Laboriosam blanditiis quo sed et qui esse ipsam necessitatibus sed.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T06:02:42.576Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          },
          {
            id: 'f48a7b44-292f-4cbe-a930-8fa71bfca42c',
            body: 'Et molestiae et excepturi maxime omnis.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T08:17:42.576Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '861fcb06-16b2-4776-9cfd-b6d990421007',
            body: 'Sint dolorem quam eum magnam.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:17:42.577Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          },
          {
            id: '71a7c571-a12c-461f-ad86-d0e9e6b01037',
            body: 'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_6.jpg',
            contentType: 'image',
            attachments: [],
            createdAt: '2023-03-29T09:32:42.577Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          },
          {
            id: '1cd59884-979c-4df2-8f00-72dac2123539',
            body: 'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_7.jpg',
            contentType: 'image',
            attachments: [],
            createdAt: '2023-03-29T09:32:42.577Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
            name: 'Reece Chung',
            username: 'reece.chung',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg',
            address: '36901 Elmer Spurs Apt. 762 - Miramar, DE / 92836',
            phone: '990-588-5716',
            email: 'letha_lubowitz24@yahoo.com',
            lastActivity: '2023-03-25T06:38:47.024Z',
            status: 'online',
            position: 'UX Designer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: 'a72fa5fb-f3bb-44ea-8d01-cd8738b81d00',
            body: 'Rerum ut iusto iste quam voluptatem necessitatibus.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T00:38:47.026Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
          },
          {
            id: '10cf0755-05ad-4e76-8235-06a543d20beb',
            body: 'Et quam in.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T08:38:47.026Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'c150c3bb-2394-484b-8f40-45c00b13129e',
            body: 'Fugit esse tenetur.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T10:33:47.026Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
          },
          {
            id: 'ae07b108-5a63-4df0-a8b8-11204a905b41',
            body: 'Dolorem dolor porro nihil cupiditate molestiae deserunt ut.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T10:35:47.026Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '67593dd3-004d-44b2-af1f-3778f4636c2c',
            body: 'Omnis beatae eos eius aut molestias laboriosam laborum et optio.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T10:37:47.026Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '024c22a2-db37-4654-909e-318985356265',
            body: 'Ut veniam quam assumenda ut voluptatibus ducimus accusamus.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T10:37:47.026Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
            name: 'Lainey Davidson',
            username: 'lainey.davidson',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg',
            address:
              '2089 Runolfsson Harbors Suite 886 - Chapel Hill, TX / 32827',
            phone: '955-439-2578',
            email: 'aditya_greenfelder31@gmail.com',
            lastActivity: '2023-03-24T04:55:12.964Z',
            status: 'away',
            position: 'Project Manager',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: '5c0367ba-73ce-41c2-b0b9-1ee9dacec6cd',
            body: 'Quos dignissimos neque omnis reiciendis voluptatem ducimus.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.965Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '74f8b163-0273-45ee-a311-2808ac867e3c',
            body: 'Laboriosam quia ut esse.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.965Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
            name: 'Cristopher Cardenas',
            username: 'cristopher.cardenas',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_7.jpg',
            address:
              '279 Karolann Ports Apt. 774 - Prescott Valley, WV / 53905',
            phone: '226-924-4058',
            email: 'lenna_bergnaum27@hotmail.com',
            lastActivity: '2023-03-23T03:55:12.964Z',
            status: 'online',
            position: 'Leader',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 2,
        messages: [
          {
            id: 'dbda28bc-010e-4127-b6d5-8953cb6bb173',
            body: 'Sit reiciendis nihil qui molestias et.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '33e0d7a6-f5cd-463f-a226-27843c549516',
            body: 'Facilis cupiditate minima ratione quaerat omnis velit non ex totam.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },

          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
            name: 'Harrison Stein',
            username: 'harrison.stein',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg',
            address: '110 Lamar Station Apt. 730 - Hagerstown, OK / 49808',
            phone: '692-767-2903',
            email: 'violet.ratke86@yahoo.com',
            lastActivity: '2023-03-26T06:55:12.964Z',
            status: 'away',
            position: 'UX Designer',
          },
        ],
        type: 'ONE_TO_ONE',

        unreadCount: 5,
        messages: [
          {
            id: 'c17ccf7c-a2bd-47c8-9f93-93da02b8dfc8',
            body: 'Provident sint esse voluptatem voluptas eveniet est.',
            contentType: 'text',
            attachments: [
              'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_2.jpg',
              'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_3.jpg',
              'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_4.jpg',
              'https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_5.jpg',
              'https://mail.google.com/mail/u/file1.docx',
            ],
            createdAt: '2023-03-26T07:25:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'edb533b5-ee86-431f-bfce-7cc67aee2cf1',
            body: 'Molestias consequatur ea facilis.',
            contentType: 'text',
            attachments: ['https://mail.google.com/mail/u/file2.xlsx'],
            createdAt: '2023-03-26T07:26:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
          },
          {
            id: 'becd699d-5337-4f7f-a39f-c4e48910dd0d',
            body: 'Tempora voluptatibus autem ut ut porro quae delectus dolorum.',
            contentType: 'text',
            attachments: ['https://mail.google.com/mail/u/file3.psd'],
            createdAt: '2023-03-26T07:27:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
          },
          {
            id: '81a709c8-2b1c-4c72-a349-7d776ec6e52c',
            body: 'Et consequatur amet nemo ducimus voluptatem placeat voluptas.',
            contentType: 'text',
            attachments: ['https://mail.google.com/mail/u/file3.pptx'],
            createdAt: '2023-03-26T07:28:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
          },
          {
            id: '381934af-232d-4ae2-a38b-7b6c9914bf7f',
            body: 'Modi iste atque hic voluptas sit quis deleniti quas consequatur.',
            contentType: 'text',
            attachments: ['https://mail.google.com/mail/u/file3.ai'],
            createdAt: '2023-03-26T07:29:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '62cdfae3-bcef-420a-a5a8-3505deed4ef4',
            body: 'Omnis est aliquid odio mollitia aliquid ex.',
            contentType: 'text',
            attachments: ['https://mail.google.com/mail/u/file3.mp4'],
            createdAt: '2023-03-26T09:55:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
            name: 'Melanie Noble',
            username: 'melanie.noble',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg',
            address:
              '96607 Claire Square Suite 591 - St. Louis Park, HI / 40802',
            phone: '552-917-1454',
            email: 'luella.ryan33@gmail.com',
            lastActivity: '2023-03-22T02:55:12.964Z',
            status: 'away',
            position: 'Backend Developer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: '3fddcb8e-2fb4-45af-af1f-428a6c8ea8f8',
            body: 'Officia possimus veniam quod molestias.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'b871e085-4456-4437-8f3f-32a8745f68d8',
            body: 'Quis veniam aut saepe aliquid nulla.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
            name: 'Chase Day',
            username: 'chase.day',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_9.jpg',
            address: '9388 Auer Station Suite 573 - Honolulu, AK / 98024',
            phone: '285-840-9338',
            email: 'joana.simonis84@gmail.com',
            lastActivity: '2023-03-21T01:55:12.964Z',
            status: 'away',
            position: 'Project Manager',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: '2d1aca33-1b49-49f1-ae94-4d1489fb11dc',
            body: 'Reprehenderit ut voluptas sapiente ratione nostrum est.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '5a828875-ef90-43de-ad38-9a03f330c6cf',
            body: 'Error ut sit vel molestias velit.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
            name: 'Shawn Manning',
            username: 'shawn.manning',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_10.jpg',
            address: '47665 Adaline Squares Suite 510 - Blacksburg, NE / 53515',
            phone: '306-269-2446',
            email: 'marjolaine_white94@gmail.com',
            lastActivity: '2023-03-20T00:55:12.964Z',
            status: 'online',
            position: 'UI Designer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: 'b783325f-88fa-4590-9def-4710a829c5b5',
            body: 'Quo quia sit nihil nemo doloremque et.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: '7bd44fdc-1006-4900-a8a9-d76b06ebac3d',
            body: 'Autem doloribus harum vero laborum.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },

          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
            name: 'Melanie Noble',
            username: 'melanie.noble',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg',
            address:
              '96607 Claire Square Suite 591 - St. Louis Park, HI / 40802',
            phone: '552-917-1454',
            email: 'luella.ryan33@gmail.com',
            lastActivity: '2023-03-22T02:55:12.964Z',
            status: 'away',
            position: 'Backend Developer',
          },
        ],
        type: 'ONE_TO_ONE',

        unreadCount: 0,
        messages: [
          {
            id: 'bd49394b-8df9-4874-b92f-85c4f5a74571',
            body: 'Tempora officiis consequuntur architecto nostrum autem nam adipisci.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-26T07:25:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'c1e99fb4-02fa-4958-b88b-2422d6f387f0',
            body: 'Voluptas sunt magni adipisci praesentium saepe.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-26T07:26:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
          },
          {
            id: '92325229-4e5d-4eb0-8c4a-8d649aec6fd6',
            body: 'Ea architecto quas voluptates voluptas earum illo est vel rem.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-26T07:27:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
          },
          {
            id: 'd89f8c37-e1fc-4278-8c7b-f79bdcdc1bb1',
            body: 'Ipsum expedita reiciendis ut.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-26T07:28:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
          },
          {
            id: '5e471c5f-cfbc-4c14-b726-75ab0a1e23fa',
            attachments: [],
            body: 'Architecto vel voluptatibus alias a aut non maxime ipsa voluptates.',
            contentType: 'text',
            createdAt: '2023-03-26T07:29:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'd17a7039-4810-43e6-8ca5-b3ba14131aea',
            body: 'Reiciendis enim officiis cupiditate eaque distinctio laudantium modi similique consequatur.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-26T09:55:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
          },
          {
            id: '66a5c252-df0f-4f60-af55-6a925eb66785',
            body: 'Ab autem consequatur itaque mollitia ipsum cupiditate error repudiandae nobis.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-26T09:55:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
          },
        ],
      },
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13': {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13',
        participants: [
          {
            id: '8864c717-587d-472a-929a-8e5f298024da-0',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg',
            name: 'Jaydon Frankie',
            username: 'jaydon.frankie',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
            name: 'Soren Durham',
            username: 'soren.durham',
            avatar:
              'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_11.jpg',
            address: '989 Vernice Flats Apt. 183 - Billings, NV / 04147',
            phone: '883-373-6253',
            email: 'vergie_block82@hotmail.com',
            lastActivity: '2023-03-18T23:55:12.964Z',
            status: 'offline',
            position: 'UI/UX Designer',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: 0,
        messages: [
          {
            id: 'fd63fdc3-2684-4741-9e44-21c94772b0f8',
            body: 'Distinctio architecto debitis eligendi consequatur unde id modi accusantium possimus.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
          },
          {
            id: 'd396bd9c-3551-42b8-bd0a-07b3796770ff',
            body: 'At ut voluptate accusantium.',
            contentType: 'text',
            attachments: [],
            createdAt: '2023-03-29T09:54:12.966Z',
            senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
          },
        ],
      },
    },
    allIds: [
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12',
      'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13',
    ],
  },
  activeConversationId: null,
  participants: [
    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
      avatar:
        'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg',
      name: 'Reece Chung',
      username: 'reece.chung',
      address: '36901 Elmer Spurs Apt. 762 - Miramar, DE / 92836',
      phone: '990-588-5716',
      email: 'letha_lubowitz24@yahoo.com',
      position: 'UX Designer',
      status: 'online',
      lastActivity: '2023-03-25T06:38:47.024Z',
    },
  ],
  recipients: [],
  categories: [],
  kycLevels: [],
  tickets: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = keyBy(contacts, 'id');
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = keyBy(conversations, 'id');
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    getAllTicketsSuccess(state, action) {
      const tickets = action.payload;
      state.tickets = tickets;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      console.log(conversation);

      if (conversation) {
        state.activeConversationId = conversation.id;
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    sendMessageSuccess(state, action) {
      const conversation = action.payload;
      const {
        conversationId,
        messageId,
        message,
        contentType,
        attachments,
        createdAt,
        senderId,
      } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    getSupportCategoriesSuccess(state, action) {
      const categories = action.payload;
      state.categories = categories;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get('/api/chat/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data.contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get('/api/chat/conversations');
      dispatch(
        slice.actions.getConversationsSuccess(response.data.conversations)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(`${API_ENDPOINTS.ticket}${id}/`);
      console.log(response);
      dispatch(slice.actions.getConversationSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendMessage(data, ticketId, isAttachment) {
  console.log(data, isAttachment);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    if (!isAttachment) {
      try {
        const response = await stbServer.post(API_ENDPOINTS.ticketReply, data);
        // dispatch(getConversation(ticketId));
        dispatch(getAllTickets());
        // dispatch(slice.actions.sendMessageSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        throw new Error('Message sending failed.');
      }
    } else {
      let token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await stbServer.post(
          API_ENDPOINTS.ticketReply,
          data,
          config
        );
        // dispatch(getConversation(ticketId));
        dispatch(getAllTickets());
        // dispatch(slice.actions.sendMessageSuccess(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        throw new Error('Message sending failed.');
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get('/api/chat/participants', {
        params: { conversationKey },
      });
      dispatch(
        slice.actions.getParticipantsSuccess(response.data.participants)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupportCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.categories);
      console.log(response);
      dispatch(slice.actions.getSupportCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createTicket(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.ticket, data);
      console.log(response);
      dispatch(getAllTickets());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getAllTickets(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.ticket);
      console.log(response);
      dispatch(slice.actions.getAllTicketsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function toggleStatus(status, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.patch(`${API_ENDPOINTS.ticket}${id}/`, {
        status,
      });
      console.log(response);

      dispatch(getAllTickets());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error?.message ?? 'Failed');
    }
  };
}
