import axios from "axios";
// config
import { HOST_BASE_URL } from "../config-global";
import { API_ENDPOINTS } from "src/api";
import { setSession } from "src/auth/utils";
import { PATH_AUTH } from "src/routes/paths";

// ----------------------------------------------------------------------

let isRefreshing = false;
let refreshPromise = null;

console.log(process.env.REACT_APP_HOST_BASE_UR, HOST_BASE_URL);
const stbServer = axios.create({
  baseURL: HOST_BASE_URL,
});

export const stbWithoutAuth = axios.create({
  baseURL: HOST_BASE_URL,
});

stbServer.interceptors.request.use(
  async function (options) {
    if (localStorage.getItem("accessToken")) {
      options.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "accessToken"
      )}`;
    }
    // options.headers['Content-Type'] = 'application/json';

    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// stbServer.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log('Error: ', error.response);
//     return Promise.reject(
//       (error.response && error.response.data) || error || 'Something went wrong'
//     );
//   }
// );

stbServer.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response?.status === 401 && !originalRequest?._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        // Use the existing refreshPromise if available
        if (!refreshPromise) {
          refreshPromise = getRefreshToken();
        }

        try {
          const newAccessToken = await refreshPromise;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return stbServer(originalRequest);
        } catch (refreshError) {
          setSession(null, null);
          console.log("acacdcsdcsdc", refreshError);
          // Reset the refreshPromise to allow subsequent attempts
          refreshPromise = null;
          window.location.href = PATH_AUTH.login;
          return Promise.reject(refreshError);
        } finally {
          originalRequest._retry = false;
          isRefreshing = false;
          refreshPromise = null;
        }
      } else {
        // If another request is already refreshing the token, wait for that promise to resolve
        return refreshPromise.then((newAccessToken) => {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          // Retry the original request
          return stbServer(originalRequest);
        });
      }
    }

    console.log(error?.response || error);
    return Promise.reject(error);
  }
);

async function getRefreshToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await stbWithoutAuth.post(API_ENDPOINTS.REFRESH_TOKEN_API, {
      refresh: refreshToken,
    });
    const { access, refresh } = response.data;
    // GlobalStore.STORE.token = access;
    // GlobalStore.STORE.refresh = refresh;
    // localStorage.setItem("accessToken", access);
    //   localStorage.setItem("refreshToken", refresh);
    setSession(access, refresh);
    return access;
  } catch (e) {
    setSession(null, null);
    isRefreshing = false;
    return Promise.reject(e);
  }
}

export default stbServer;
