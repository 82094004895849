import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFAutocomplete({
  name,
  label,
  helperText,
  onChange = () => {},
  ...other
}) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            setValue(name, newValue, { shouldValidate: true });
            onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              label={label}
              error={!!error}
              helperText={
                <FormHelperText sx={{ fontSize: 14, margin:0, padding:0 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              }
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
