import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ENDPOINTS } from 'src/api';
import stbServer from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isBusinessDocsLoading: true,
  isPersonalDocsLoading: true,
  error: null,
  kycLevels: [],
  personalKycDocTypes: [],
  timezones:[],
  personalInfo: null,
  personalDocuments: null,
  businessInfo: null,
  businessCategories: [],
  businessTypes: [],
  shareholdersList: [],
  businessDocuments: null,
  occupationTypes: [],
  businessDocTypes: [],
};

const slice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    toggleBusinessDocsLoading(state) {
      state.isBusinessDocsLoading = !state.isBusinessDocsLoading;
    },

    togglePersonalDocsLoading(state) {
      state.isPersonalDocsLoading = !state.isPersonalDocsLoading;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET KYCLEVELS
    getKycLevelsSuccess(state, action) {
      state.isLoading = false;
      state.kycLevels = action.payload;
    },

    getPersonalKycDocTypesSuccess(state, action) {
      state.isLoading = false;
      state.personalKycDocTypes = action.payload;
    },

    getTimezonesSuccess(state, action) {
      state.isLoading = false;
      state.timezones = action.payload;
    },

    getPersonalInfoSuccess(state, action) {
      state.isLoading = false;
      state.personalInfo = action.payload;
    },

    getPersonalDocumentsSuccess(state, action) {
      state.isPersonalDocsLoading = false;
      state.personalDocuments = action.payload;
    },

    getBusinessInfoSuccess(state, action) {
      state.isLoading = false;
      state.businessInfo = action.payload;
    },

    getBusinessDocTypesSuccess(state, action) {
      state.isLoading = false;
      state.businessDocTypes = action.payload;
    },

    getBusinessCategorySuccess(state, action) {
      state.isLoading = false;
      state.businessCategories = action.payload;
    },

    getBusinessTypeSuccess(state, action) {
      state.isLoading = false;
      state.businessTypes = action.payload;
    },

    getShareholdersListSuccess(state, action) {
      state.isLoading = false;
      state.shareholdersList = action.payload;
    },

    getBusinessDocsSuccess(state, action) {
      state.isBusinessDocsLoading = false;
      state.businessDocuments = action.payload;
    },

    getOccupationTypesSuccess(state, action) {
      state.isLoading = false;
      state.occupationTypes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getKycLevels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.kycLevels);
      console.log(response);
      dispatch(slice.actions.getKycLevelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpgradeTicket(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.ticket, data);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getTimezones(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.timezones);
      dispatch(slice.actions.getTimezonesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getPersonalInfo(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(`${API_ENDPOINTS.shareholder}${id}/`);
      dispatch(slice.actions.getPersonalInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getPersonalDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.togglePersonalDocsLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.shareholderKyc);
      dispatch(slice.actions.getPersonalDocumentsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.togglePersonalDocsLoading());
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getBusinessInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.senderBusiness);
      dispatch(slice.actions.getBusinessInfoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}
export function getBusinessDocTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessDocTypes);
      dispatch(slice.actions.getBusinessDocTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}


export function getBusinessCategory() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessCategory);
      dispatch(slice.actions.getBusinessCategorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getBusinessType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessType);
      console.log('ad23d3', response)
      dispatch(slice.actions.getBusinessTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getShareholdersList(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.shareholder);
      dispatch(slice.actions.getShareholdersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getBusinessDocs() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleBusinessDocsLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessKyb);
      dispatch(slice.actions.getBusinessDocsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.toggleBusinessDocsLoading());
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}

export function getOccupationTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.occupations);
      dispatch(slice.actions.getOccupationTypesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}
export function initiateKyc() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.kybSubmit);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}





