import { Helmet } from 'react-helmet-async';
// sections
import Login from '../../sections/auth/Login';
import Page from 'src/components/page';
// import Login from '../../sections/auth/LoginAuth0';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Page title={'Login'}>
        <Login />
      </Page>
    </>
  );
}
