import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ disabledLink = false, sx, isFull, showFull = true, theme = null, ...other }, ref) => {
    let {themeMode} = useSettingsContext();
    const { mtoConfig } = useSelector((state) => state.config);

    let logoImage = mtoConfig?.logo_colour_vertical;

    if(theme){
      themeMode = theme
    }

    if(themeMode === 'light'){
      if(showFull){
        logoImage = mtoConfig?.logo_colour_vertical;
      } else {
        logoImage = mtoConfig?.logo_colour_horizon;
      }
    } else {
      if(showFull){
        logoImage = mtoConfig?.logo_light_vertical;
      } else {
        logoImage = mtoConfig?.logo_light_horizon;
      }
    }

    const getImage = () => {
      return logoImage
    }

    
    const logo = (
      <Box
        component="img"
        src={getImage()}
        sx={{ width: showFull? 40 : 220, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
