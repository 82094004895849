import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format('0.00');
}

export function fCurrency(number) {
  const format = numeral(number).format('$0,0.00');

  return result(format, '.00');
}

export function fShortenCurrency(number) {
  const format = numeral(number).format('$0,0.00a');
  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number)).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function formatCurrency(
  value = 0,
  decimal = 2,
  symbol = '',
  prefix = false
) {
  let trimmedValue = Intl.NumberFormat('en-US').format(
    Number(Number(value).toFixed(decimal))
  );
  return prefix ? `${symbol} ${trimmedValue}` : `${trimmedValue} ${symbol}`;
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
