import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import signupReducer from './slices/signup';
import kycReducer from './slices/kyc';
import configReducer from './slices/config';
import remittanceReducer from './slices/remittance';
import dashboardReducer from './slices/dashboard';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  signup: signupReducer,
  kyc: kycReducer,
  config: configReducer,
  remittance: remittanceReducer,
  dash: dashboardReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export default rootReducer;
